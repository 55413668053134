import React, { useState } from "react";
import InputHeader from "./InputHeader";
import Loader from "components/loader";
import useAutoFocus from "hooks/useAutoFocus";

const TranslationBox = ({
  translationText = "",
  setTranslationText,
  generateReport,
  lang,
  loader,
  isDisabled,
}) => {
  useAutoFocus("textarea");

  const handleInputTextChange = (e) => {
    const value = e.target.value;
    setTranslationText(value);
  };

  return (
    <div
      className={`relative w-full
       p-3 bg-white rounded-lg shadow-box border-none mt-4 lg:mt-0`}
    >
      <InputHeader
        title="Translated text"
        text={translationText}
        setText={setTranslationText}
      />
      <div className="relative">
        <textarea
          autoFocus
          id="input-textarea"
          dir={lang === "fa" || lang === "he" || lang === "ar" ? "rtl" : "ltr"}
          className={` h-[200px] anonymize_tool font-primary  placeholder-[#B4B4B4] text-black leading-[26px] py-3 bg-transparent text-base overscroll-y-none resize-none border-none outline-none block w-full`}
          placeholder="Enter your translated text here"
          value={translationText}
          dangerouslySetInnerHTML={{
            __html: translationText.replace(/\n/g, "<br />"),
          }}
          onChange={handleInputTextChange}
        />
      </div>
      <div className="flex justify-between items-center mt-4">
        <p className="text-right mt-2 text-sm text-[#9C9C9C]">
          {
            translationText
              .trim()
              .split(/\s+/)
              .filter((word) => word.length > 0).length
          }{" "}
          words
        </p>
      </div>
      <div className="flex justify-between items-center mt-3 gap-3">
        <button
          onClick={generateReport}
          disabled={isDisabled}
          type="button"
          className={`flex-1 ${
            isDisabled ? "bg-grayInput" : "bg-[#5B93FF]"
          }  py-3 px-6 text-base font-semibold font-opensans text-white rounded-lg`}
        >
          {loader ? <Loader /> : "Evaluate Translation"}
        </button>

        <button
          type="button"
          className="flex-[0.07] border-[1.5px] rounded-lg border-[#5B93FF] grid place-content-center p-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
          >
            <path
              d="M1 8C1 8 1.12132 7.15076 4.63604 3.63604C8.15076 0.12132 13.8492 0.12132 17.364 3.63604C18.6092 4.88131 19.4133 6.40072 19.7762 8M1 8V2M1 8H7M21 12C21 12 20.8787 12.8492 17.364 16.364C13.8492 19.8787 8.15076 19.8787 4.63604 16.364C3.39076 15.1187 2.58669 13.5993 2.22383 12M21 12V18M21 12H15"
              stroke="#5B93FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TranslationBox;
