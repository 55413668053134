import React, { useState, useEffect } from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import ToolsBanner from "components/commontools/toolsBanner";
import InputBox from "components/PostTranslation/Input";
import TranslationBox from "components/PostTranslation/TranslationBox";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { navigate } from "gatsby";
import DetailAnalysis from "components/QualityTools/DetailAnalysis";
import useDebounce from "components/QualityTools/useDebounce";
import axios from "axios";
import { Languages } from "components/QualityTools/LanguagesData";
import detectLanguage from "utils/detectLanguage";
const apiEndpoints = [
  "translationQuality",
  "glossaryGenerator",
  "consistencyChecker",
];

const Index = () => {
  const [sourceText, setSourceText] = useState("");
  const [translationText, setTranslationText] = useState("");
  const [shareId, setShareId] = useState(null);
  const [apiCall, setApiCall] = useState(0);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const debouncedInputText = useDebounce(sourceText, 3000);
  const debouncedOutputText = useDebounce(translationText, 3000);
  const [outputLang, setOutputLang] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const responses = [];


  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);

  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectLanguageForTarget(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectLanguageForSource = async (text) => {
    const { languageCode, languageName } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
      setTranslateFromInput(languageName);
    }
  };

  const detectLanguageForTarget = async (text) => {
    const { languageCode, languageName } = await detectLanguage(text);
    if (languageCode) {
      setOutputLang(languageCode);
      setTranslateToInput(languageName);
    }
  };


  const generateShareId = async () => {
    const { share_id } = await http().get("/api/v2/generate-share-id");
    setShareId(share_id);
  };
  useEffect(() => {
    generateShareId();
  }, []);

  const getOutputs = async () => {
    setApiCall((preVal) => preVal + 1);
    try {
      await Promise.all(
        apiEndpoints.map(async (tool_name) => {
          try {
            const res = await http().post(endpoints.forms.aiTools, {
              tool_name,
              source_text: sourceText,
              translation: translationText,
              share_id: shareId,
              source_language_code: inputLang,
              target_language_code: outputLang,
            });
            responses.push(res);
          } catch (error) {
            console.error(`Failed API call for tool_name: ${tool_name}`, error);
            setError(error);
          } finally {
            setError(false);
          }
        })
      );
    } catch (error) {
      console.error("Error during API calls:", error);
    } finally {
      setLoader(false);
    }
  };
  const generateReport = async () => {
    setLoader(true);
    setError("");
    if (apiCall < 3 && responses?.length < 6) {
      await getOutputs();
    }
    if (shareId === undefined && !responses.success) {
      setError("Unable to generate a response. Please try again.");
      return;
    }
    navigate(`/tools/post-translation/result?share-id=${shareId}`);
  };
 const isDisabled =  !sourceText || !translationText || !inputLang || !outputLang
  return (
    <Layout>
      <SEO
        title="Post-Translation Toolkit"
        description="The Tomedes Post-Translation Toolkit is designed to enhance translated content by evaluating quality, generating bilingual glossaries, and ensuring consistency of terminology."
        slug="/tools/pre-translation"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-7">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Post-Translation Toolkit"
            title="Get a Clear Assessment of Your Translation’s Strengths and Weaknesses"
          />
          <div className="grid md:grid-cols-2 mt-10 gap-4">
            <InputBox sourceText={sourceText} setSourceText={setSourceText} lang = {inputLang} isDisabled={isDisabled}/>
            <TranslationBox
              translationText={translationText}
              setTranslationText={setTranslationText}
              generateReport={generateReport}
              loader={loader}
              lang = {outputLang}
              isDisabled={isDisabled}
            />
          </div>

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans text-[#424242]">
            The Tomedes Post-Translation Toolkit is a tool that helps make sure
            translated content is of good quality and consistent. It is made for
            translators, localization professionals, and businesses. It can be
            used to evaluate translations, generate bilingual glossaries, and
            identify inconsistencies. Whether you need to assess fluency,
            maintain consistent terminology, or improve the accuracy of your
            translations, this toolkit provides everything you need to deliver
            polished results.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
